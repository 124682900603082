import React from 'react'

function Datenschutz() {
  return (
    <>
      <h2>1. Datenschutz auf einen Blick</h2>
      <h4>Allgemeine Hinweise</h4>
      <p>
        Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit
        Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen.
        Personenbezogene Daten sind alle Daten, mit denen Sie persönlich
        identifiziert werden können. Ausführliche Informationen zum Thema
        Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
        Datenschutzerklärung.
      </p>
      <h4>Datenerfassung auf dieser Website</h4>
      <h4>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</h4>
      <p>
        Die Datenverarbeitung auf dieser Website erfolgt durch den
        Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum dieser
        Website entnehmen.
      </p>
      <h4>Wie erfassen wir Ihre Daten?</h4>
      <p>
        Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
        mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie mir per
        E-Mail senden.
      </p>
      <p>
        Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch
        der Website durch unsere IT-Systeme erfasst. Das sind vor allem
        technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit des
        Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald
        Sie diese Website betreten.
      </p>
      <h4>Wofür nutzen wir Ihre Daten?</h4>
      <p>
        Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der
        Website zu gewährleisten. Andere Daten können zur Analyse Ihres
        Nutzerverhaltens verwendet werden.
      </p>
      <h4>Welche Rechte haben Sie bezüglich Ihrer Daten?</h4>
      <p>
        Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft,
        Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
        erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung
        dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur
        Datenverarbeitung erteilt haben, können Sie diese Einwilligung jederzeit
        für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter
        bestimmten Umständen die Einschränkung der Verarbeitung Ihrer
        personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
        Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
      </p>
      <p>
        Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich
        jederzeit unter der im Impressum angegebenen Adresse an uns wenden.
      </p>
      <h2>2. Hosting und Content Delivery Networks (CDN)</h2>
      <h4>Externes Hosting</h4>
      <p>
        Diese Website wird bei einem externen Dienstleister gehostet (Hoster).
        Die personenbezogenen Daten, die auf dieser Website erfasst werden,
        werden auf den Servern des Hosters gespeichert. Hierbei kann es sich v.
        a. um IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten,
        Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige Daten,
        die über eine Website generiert werden, handeln.
      </p>
      <p>
        Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung
        gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1
        lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten
        Bereitstellung unseres Online-Angebots durch einen professionellen
        Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
      </p>
      <p>
        Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur
        Erfüllung seiner Leistungspflichten erforderlich ist und unsere
        Weisungen in Bezug auf diese Daten befolgen.
      </p>
      <p>Wir setzen folgenden Hoster ein:</p>
      <p>
        DigitalOcean, LLC.
        <br />
        101 Avenue of the Americas
        <br />
        10th Floor
        <br />
        New York - NY 10013
        <br />
        United States of America
      </p>
      <p>Telefon: +1 888 890 6714</p>
      <p>E-Mail: privacy@digitakocean.com </p>
      <p>
        Website:{' '}
        <a
          href='https://www.digitalocean.com'
          target='_blank'
          rel={'noreferrer'}
        >
          https://www.digitalocean.com
        </a>{' '}
      </p>
      <p>
        Die von uns in Anspruch genommenen Hosting-Leistungen dienen der
        Zurverfügungstellung der folgenden Leistungen: Infrastruktur- und
        Plattformdienstleistungen, Rechenkapazität, Speicherplatz und
        Datenbankdienste, Sicherheitsleistungen sowie technische
        Wartungsleistungen, die wir zum Zwecke des Betriebs dieses
        Onlineangebotes einsetzen.
      </p>
      <p>
        Hierbei verarbeiten wir, bzw. unser Hostinganbieter Bestandsdaten,
        Kontaktdaten, Inhaltsdaten, Vertragsdaten, Nutzungsdaten, Meta- und
        Kommunikationsdaten von Kunden, Interessenten und Besuchern dieses
        Onlineangebotes auf Grundlage unserer berechtigten Interessen an einer
        effizienten und sicheren Zurverfügungstellung dieses Onlineangebotes
        gem. Art. 6 Abs. 1 lit. f DSGVO i.V.m. Art. 28 DSGVO (Abschluss
        Auftragsverarbeitungsvertrag).
      </p>
      <p>
        Die unserem Service zugrunde liegenden Server befinden sich in den
        Rechenzentren der Firma Interxion in Frankfurt. Der Serverstandort ist
        unter anderem ISO 27001 zertifiziert. Die Stellplätze im Rechenzentrum
        wurden von DigitalOcean Inc., New York angemietet. Digital Ocean
        betreibt dort eine Cloud-Plattform für virtuelle Server, welche von für
        die Bereitstellung unseres Service genutzt wird. Ein
        Auftragsdatenverarbeitungsvertrag mit DigitalOcean wurde geschlossen.
        Zusätzlich unterliegt DigitalOcean dem EU-US Privacy-Shield-Abkommen.
      </p>
      <p>
        Erhobene personenbezogene Daten: verschiedene Datenarten, wie in der
        Datenschutzerklärung des Dienstes beschrieben.
      </p>
      <h4>Erhebung von Zugriffsdaten und Logfiles</h4>
      <p>
        Wir, bzw. unser Hostinganbieter, erhebt auf Grundlage unserer
        berechtigten Interessen im Sinne des Art. 6 Abs. 1 lit. f. DSGVO Daten
        über jeden Zugriff auf den Server, auf dem sich dieser Dienst befindet
        (sogenannte Serverlogfiles). Zu den Zugriffsdaten gehören Name der
        abgerufenen Website, Datei, Datum und Uhrzeit des Abrufs, übertragene
        Datenmenge, Meldung über erfolgreichen Abruf, Browsertyp nebst Version,
        das Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte Seite),
        IP-Adresse und der anfragende Provider.
      </p>
      <p>
        Logfile-Informationen werden aus Sicherheitsgründen (z. B. zur
        Aufklärung von Missbrauchs- oder Betrugshandlungen) für die Dauer von
        maximal 7 Tagen gespeichert und danach gelöscht. Daten, deren weitere
        Aufbewahrung zu Beweiszwecken erforderlich ist, sind bis zur endgültigen
        Klärung des jeweiligen Vorfalls von der Löschung ausgenommen.
      </p>
      <h2>3. Allgemeine Hinweise und Pflicht­informationen</h2>
      <h4>Datenschutz</h4>
      <p>
        Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
        sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
        entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
        Datenschutzerklärung.
      </p>
      <p>
        Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
        Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
        persönlich identifiziert werden können. Die vorliegende
        Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir
        sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.
      </p>
      <p>
        Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei
        der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
        lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
        möglich.
      </p>
      <h4>Hinweis zur verantwortlichen Stelle</h4>
      <p>
        Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website
        ist:
      </p>
      <p>
        Paul Mura
        <br />
        Anemonenweg 7
        <br />
        97084 Würzburg
      </p>
      <p>
        Telefon: 0157/58319772
        <br />
        E-Mail: info@paulmura.com
      </p>
      <p>
        Verantwortliche Stelle ist die natürliche oder juristische Person, die
        allein oder gemeinsam mit anderen über die Zwecke und Mittel der
        Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen
        o. Ä.) entscheidet.
      </p>
      <h4>Speicherdauer</h4>
      <p>
        Soweit innerhalb dieser Datenschutzerklärung keine speziellere
        Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten bei
        uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein
        berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur
        Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern wir
        keinen anderen rechtlich zulässigen  Gründe für die Speicherung Ihrer
        personenbezogenen Daten haben (z.B. steuer- oder handelsrechtliche
        Aufbewahrungsfristen); im letztgenannten Fall erfolgt die Löschung nach
        Fortfall dieser Gründe.
      </p>
      <h4>Gesetzlich vorgeschriebener Datenschutz­beauftragter</h4>
      <p>
        Paul Mura
        <br />
        Anemonenweg 7
        <br />
        97084 Würzburg
      </p>
      <p>
        Telefon: 0157/58319772
        <br />
        E-Mail: info@paulmura.com
      </p>
      <h4>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h4>
      <p>
        Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
        Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
        jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
        Datenverarbeitung bleibt vom Widerruf unberührt.
      </p>
      <h4>
        Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie
        gegen Direktwerbung (Art. 21 DSGVO)
      </h4>
      <p>
        WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F
        DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS
        IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER
        PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN
        AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE
        RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE
        DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR
        IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI
        DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG
        NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER
        DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG
        VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).
      </p>
      <p>
        WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
        BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE
        VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE
        DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT
        ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
        WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT
        MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21
        ABS. 2 DSGVO).
      </p>
      <h4>Beschwerde­recht bei der zuständigen Aufsichts­behörde</h4>
      <p>
        Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
        Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
        Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder
        des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht
        unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher
        Rechtsbehelfe.
      </p>
      <h4>Recht auf Daten­übertrag­barkeit</h4>
      <p>
        Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
        oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder
        an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen
        zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen
        Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch
        machbar ist.
      </p>
      <h4>SSL- bzw. TLS-Verschlüsselung</h4>
      <p>
        Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung
        vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die
        Sie an uns als Seitenbetreiber senden, eine SSL- bzw.
        TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran,
        dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt
        und an dem Schloss-Symbol in Ihrer Browserzeile.
      </p>
      <p>
        Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten,
        die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
      </p>
      <h4>Auskunft, Löschung und Berichtigung</h4>
      <p>
        Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
        das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
        personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der
        Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung
        dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene
        Daten können Sie sich jederzeit unter der im Impressum angegebenen
        Adresse an uns wenden.
      </p>
      <h4>Recht auf Einschränkung der Verarbeitung</h4>
      <p>
        Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
        personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit
        unter der im Impressum angegebenen Adresse an uns wenden. Das Recht auf
        Einschränkung der Verarbeitung besteht in folgenden Fällen:
      </p>
      <p>
        - Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen
        Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu
        überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die
        Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
        verlangen.
      </p>
      <p>
        - Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
        geschah/geschieht, können Sie statt der Löschung die Einschränkung der
        Datenverarbeitung verlangen.
      </p>
      <p>
        -Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie
        jedoch zur Ausübung, Verteidigung oder Geltendmachung von
        Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die
        Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
        verlangen.
      </p>
      <p>
        - Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben,
        muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen
        werden. Solange noch nicht feststeht, wessen Interessen überwiegen,
        haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer
        personenbezogenen Daten zu verlangen.
      </p>
      <p>
        Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt
        haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit
        Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung
        von Rechtsansprüchen oder zum Schutz der Rechte einer anderen
        natürlichen oder juristischen Person oder aus Gründen eines wichtigen
        öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats
        verarbeitet werden.
      </p>
      <h2>4. Datenerfassung auf dieser Website</h2>
      <h2>Cookies</h2>
      <p>
        Unsere Website verwendet HTTP-Cookies um nutzerspezifische Daten zu
        speichern.
        <br />
        Ein Cookie ist ein kurzes Datenpaket, welches zwischen Webbrowser und
        Webserver ausgetauscht wird, für diese aber völlig bedeutungslos ist und
        erst für die Webanwendung, z. B. einen Online-Shop, eine Bedeutung
        erhält, etwa den Inhalt eines virtuellen Warenkorbes.
      </p>
      <p>
        Es gibt zwei Arten von Cookies: Erstanbieter-Cookies werden von unserer
        Website erstellt, Drittanbieter-Cookies werden von anderen Websites (z.
        B. Google Analytics) erstellt.
        <br />
        Man unterscheidet drei Kategorien von Cookies: unbedingt notwendige
        Cookies um grundlegende Funktionen der Website sicherzustellen,
        funktionelle Cookies um die Leistung der Webseite sicherzustellen und
        zielorientierte Cookies um das Benutzererlebnis zu verbessern.
      </p>
      <p>
        Wir nutzen Cookies, um unsere Webseite nutzerfreundlicher zu gestalten.
        Einige Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese
        löschen. Sie ermöglichen es uns, Ihren Browser beim nächsten Besuch
        wiederzuerkennen.
      </p>
      <h3>Cookie Einstellungen sehen und Cookies löschen</h3>
      <p>
        Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser
        gespeichert wurden, Cookie-Einstellungen ändern oder Cookies löschen
        möchten, können Sie dies in Ihren Browser-Einstellungen finden:
      </p>
      <ul>
        <li>
          <a
            href='https://support.apple.com/kb/ph21411?locale=de_AT'
            target='_blank'
            rel='noopener nofollow'
          >
            Safari: Verwalten von Cookies und Websitedaten mit Safari
          </a>
        </li>
        <li>
          <a
            href='https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen'
            target='_blank'
            rel='noopener nofollow'
          >
            Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf
            Ihrem Computer abgelegt haben
          </a>
        </li>
        <li>
          <a
            href='https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;hl=de'
            target='_blank'
            rel='noopener nofollow'
          >
            Chrome: Cookies in Chrome löschen, aktivieren und verwalten
          </a>
        </li>
        <li>
          <a
            href='https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies'
            target='_blank'
            rel='noopener nofollow'
          >
            Internet Explorer: Löschen und Verwalten von Cookies
          </a>
        </li>
      </ul>
      <p>
        Wenn Sie die Speicherung von Daten in Cookies nicht wünschen, so können
        Sie Ihren Browser so einrichten, dass er Sie über das Setzen von Cookies
        informiert und Sie dies nur im Einzelfall erlauben.&nbsp;Sie können
        jederzeit Cookies, die sich bereits auf Ihrem Computer befinden, löschen
        oder Cookies deaktivieren. Die Vorgangsweise dazu ist nach Browser
        unterschiedlich, am besten Sie suchen die Anleitung in Google mit dem
        Suchbegriff “Cookies löschen Chrome” oder „Cookies deaktivieren Chrome“
        im Falle eines Chrome Browsers oder tauschen das Wort „Chrome“ gegen den
        Namen Ihres Browsers, z. B. Edge, Firefox, Safari aus.
        <br />
        Wenn Sie uns generell nicht gestatten, Cookies zu nutzen, d.h. diese per
        Browsereinstellung deaktivieren, können manche Funktionen und Seiten
        nicht wie erwartet funktionieren.
      </p>
      <h2>Cloudflare Datenschutzerklärung</h2>
      <p>
        Wir verwenden auf dieser Website Cloudflare der Firma Cloudflare, Inc.
        (101 Townsend St., San Francisco, CA 94107, USA) um die Website
        schneller und sicherer zu machen. Dabei verwendet Cloudflare Cookies und
        verarbeitet Besucherdaten über die wir Sie im Folgenden informieren.
      </p>
      <p>
        Der Cookie von Cloudflare (__cfduid) wird verwendet, um einzelne
        Besucher hinter einer gemeinsam genutzten IP-Adresse zu identifizieren
        und Sicherheitseinstellungen für jeden einzelnen Besucher anzuwenden.
        Wenn sich ein Besucher dieser Website zum Beispiel in einem Lokal
        befindet, in dem sich eine Reihe infizierter Computer befinden, aber der
        Computer des bestimmten Besuchers vertrauenswürdig ist, können wir dies
        anhand des Cookies erkennen. Der Cookie entspricht keiner Benutzer-ID
        und speichert keine personenbezogenen Daten.
        <br />
        Dieser Cookie ist für die Cloudflare-Sicherheitsfunktionen unbedingt
        erforderlich und kann nicht deaktiviert werden.
      </p>
      <h3>Cookies von Cloudflare</h3>
      <ul>
        <li>
          __cfduid
          <ul>
            <li>Ablaufzeit: 1 Jahr</li>
            <li>
              Verwendung: Sicherheitseinstellungen für jeden einzelnen Besucher
            </li>
            <li>
              Beispielhafter Wert:&nbsp;d798bf7df9c1ad5b7583eda5cc5e78211071587
            </li>
          </ul>
        </li>
      </ul>
      <p>
        Cloudflare bietet Web-Optimierung und Sicherheitsdienste, um Websites zu
        verbessern und zu schützen. Dazu gehören ein Reverse-Proxy, ein
        Passthrough-Sicherheitsdienst und ein Content-Verteilungsnetzwerk.
        Cloudflare erfasst Informationen der Webseitebesucher. Diese Information
        kann IP-Adressen, Systemkonfigurationsinformationen und andere
        Informationen über den Verkehr von und zur Website umfassen, ist aber
        nicht darauf beschränkt. Cloudflare erfasst und verwendet Logdaten, um
        ihre Dienstleistungen gemäß der Kundenvereinbarungen zu betreiben, zu
        warten und zu verbessern. Zum Beispiel können Logdaten Cloudflare dabei
        helfen, neue Bedrohungen zu erkennen, böswillige Dritte zu
        identifizieren und dieser Website einen stabileren Sicherheitsschutz zu
        bieten.
      </p>
      <p>
        Cloudflare ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework
        wodurch der korrekte und sichere Datentransfer persönlicher Daten
        geregelt wird. Mehr Informationen dazu finden Sie auf&nbsp;
        <a
          href='https://www.privacyshield.gov/participant?id=a2zt0000000GnZKAA0&amp;tid=211071587'
          rel='nofollow'
        >
          https://www.privacyshield.gov/participant?id=a2zt0000000GnZKAA0
        </a>
        .<br />
        Mehr Informationen zum Datenschutz bei Cloudflare finden Sie auf&nbsp;
        <a
          href='https://www.cloudflare.com/de-de/privacypolicy/?tid=211071587'
          target='_blank'
          rel='noopener nofollow'
        >
          https://www.cloudflare.com/de-de/privacypolicy/
        </a>
        .
      </p>
      <h4>Cookies</h4>
      <p>
        Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind
        kleine Textdateien und richten auf Ihrem Endgerät keinen Schaden an. Sie
        werden entweder vorübergehend für die Dauer einer Sitzung
        (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem Endgerät
        gespeichert. Session-Cookies werden nach Ende Ihres Besuchs automatisch
        gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät gespeichert, bis
        Sie diese selbst löschen oder eine automatische Löschung durch Ihren
        Webbrowser erfolgt.
      </p>
      <p>
        Teilweise können auch Cookies von Drittunternehmen auf Ihrem Endgerät
        gespeichert werden, wenn Sie unsere Seite betreten
        (Third-Party-Cookies). Diese ermöglichen uns oder Ihnen die Nutzung
        bestimmter Dienstleistungen des Drittunternehmens (z.B. Cookies zur
        Abwicklung von Zahlungsdienstleistungen).
      </p>
      <p>
        Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch
        notwendig, da bestimmte Websitefunktionen ohne diese nicht funktionieren
        würden (z.B. die Warenkorbfunktion oder die Anzeige von Videos). Andere
        Cookies dienen dazu, das Nutzerverhalten auszuwerten oder Werbung
        anzuzeigen.
      </p>
      <p>
        Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs
        (notwendige Cookies) oder zur Bereitstellung bestimmter, von Ihnen
        erwünschter Funktionen (funktionale Cookies, z. B. für die
        Warenkorbfunktion) oder zur Optimierung der Website (z.B. Cookies zur
        Messung des Webpublikums) erforderlich sind, werden auf Grundlage von
        Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine andere
        Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein
        berechtigtes Interesse an der Speicherung von Cookies zur technisch
        fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern eine
        Einwilligung zur Speicherung von Cookies abgefragt wurde, erfolgt die
        Speicherung der betreffenden Cookies ausschließlich auf Grundlage dieser
        Einwilligung (Art. 6 Abs. 1 lit. a DSGVO); die Einwilligung ist
        jederzeit widerrufbar.
      </p>
      <p>
        Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
        Cookies informiert werden und Cookies nur im Einzelfall erlauben, die
        Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie
        das automatische Löschen der Cookies beim Schließen des Browsers
        aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität
        dieser Website eingeschränkt sein.
      </p>
      <p>
        Soweit Cookies von Drittunternehmen oder zu Analysezwecken eingesetzt
        werden, werden wir Sie hierüber im Rahmen dieser Datenschutzerklärung
        gesondert informieren und ggf. eine Einwilligung abfragen.
      </p>
      <h4>Anfrage per E-Mail oder Telefon</h4>
      <p>
        Wenn Sie uns per E-Mail oder Telefon kontaktieren, wird Ihre Anfrage
        inklusive aller daraus hervorgehenden personenbezogenen Daten (Name,
        Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert
        und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung
        weiter.
      </p>
      <p>
        Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
        lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
        zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
        erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf
        unserem berechtigten Interesse an der effektiven Bearbeitung der an uns
        gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
        Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde.
      </p>
      <p>
        Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben
        bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
        Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt
        (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende
        gesetzliche Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen
        – bleiben unberührt.
      </p>
    </>
  )
}

export default Datenschutz
