import React, { useEffect, useRef, useState } from 'react'
import { BrowserRouter as Router, Link, useLocation } from 'react-router-dom'
import { animated, useSpring } from 'react-spring'
import './App.css'
import linkedin from './assets/images/linkedin.svg'
import logo from './assets/images/paul_mura-logo.svg'
import xing from './assets/images/xing.svg'
import CookieConsent from './components/CookieConsent'
import Datenschutz from './components/Datenschutz'
import Impressum from './components/Impressum'
import Mover from './components/Mover'

function App() {
  const [activeImpressum, setactiveImpressum] = useState(null)
  const defaultConfig = { mass: 1, tension: 350, friction: 150 }
  const [cookie, setcookie] = useState(true)

  const firstname = [
    {
      letter: 'P',
      start: `translate(0px, 0px) scale(1, 1)`,
      delay: 1400,
      useRandom: false,
    },
    {
      letter: 'A',
      start: `translate(-40px, 0px) scale(1, 1)`,
      delay: 400,
      useRandom: false,
    },
    {
      letter: 'U',
      start: `translate(0px, 0px) scale(1, 1)`,
      delay: 1800,
      useRandom: false,
    },
    {
      letter: 'L',
      start: `translate(50px, 0px) scale(1, 1)`,
      delay: 0,
      useRandom: false,
    },
  ]
  const lastname = [
    {
      letter: 'M',
      start: `translate(0px, 0px) scale(1, 1)`,
      delay: 1600,
      useRandom: false,
    },
    {
      letter: 'U',
      start: `translate(0px, -40px) scale(1, 1)`,
      delay: 500,
      useRandom: false,
    },
    {
      letter: 'R',
      start: `translate(0px, 0px) scale(1, 1)`,
      delay: 1000,
      useRandom: false,
    },
    {
      letter: 'A',
      start: `translate(0px, 40px) scale(1, 1)`,
      delay: 800,
      useRandom: false,
    },
  ]
  const waiter = 100

  const moreInfoSetter = (info = null) => {
    setactiveImpressum(info)
    setcookie(false)
  }

  return (
    <Router>
      <div className='view'>
        <Link to={'/'} id={'logo'}>
          <img src={logo} alt={'Paul Mura Software Engineer Logo'} />
        </Link>
        <div className={'App'}>
          <div className='animate name'>
            <h1>
              {firstname.map((item, index) => {
                return (
                  <Mover
                    key={`pola-${index}-`}
                    letter={item.letter}
                    start={item.start}
                    delay={item.delay}
                    useRandom={item.useRandom}
                  ></Mover>
                )
              })}
              {lastname.map((item, index) => {
                return (
                  <Mover
                    key={`symbiose-${index}`}
                    letter={item.letter}
                    start={item.start}
                    cssClass={'bold'}
                    delay={item.delay}
                    useRandom={item.useRandom}
                  />
                )
              })}
            </h1>
          </div>
          <div className='address'>

            <p className={'construction'}>
              <Mover
                delay={waiter + 1900}
                start={`translate(-4px, 0px) scale(1, 1)`}
                customConfig={defaultConfig}
                letter={'IT Consulting &'}
                useRandom={false}
              />
            </p>
            <p className={'construction'}>
              <Mover
                delay={waiter + 1900}
                start={`translate(-4px, 0px) scale(1, 1)`}
                customConfig={defaultConfig}
                letter={'Web / App / Software Development'}
                useRandom={false}
              />
            </p>
            <p>
              <Mover
                delay={waiter + 2000}
                start={`translate(-6px, 0px) scale(1, 1)`}
                customConfig={defaultConfig}
                letter={'React - React Native - TypeScript - JavaScript'}
                useRandom={false}
              />
            </p>
            <p>
              <Mover
                delay={waiter + 2100}
                start={`translate(-6px, 0px) scale(1, 1)`}
                customConfig={defaultConfig}
                letter={'HTML5 - CSS3 - Sass - Git - Node.js - Docker'}
                useRandom={false}
              />
            </p>
          </div>
          <div className={'referenzen top5'}>
            <p className={'construction'}>
              <Mover
                delay={waiter + 2200}
                start={`translate(-4px, 0px) scale(1, 1)`}
                customConfig={defaultConfig}
                letter={'Referenzen'}
                useRandom={false}
              />
            </p>
            <p>
              <Mover
                delay={waiter + 2300}
                start={`translate(-4px, 0px) scale(1, 1)`}
                customConfig={defaultConfig}
                letter={'App // 1'}
                useRandom={false}
              />
              <span style={{ marginLeft: 1 }}>
                <Mover
                  delay={waiter + 2300}
                  start={`translate(-4px, 0px) scale(1, 1)`}
                  customConfig={defaultConfig}
                  letter={'Live - '}
                  useRandom={false}
                />
              </span>
              <a
                href={
                  'https://play.google.com/store/apps/details?id=de.wdr.einslive&hl=de&gl=US'
                }
                target={'_blank'}
                rel={'noreferrer'}
              >
                <Mover
                  delay={waiter + 2300}
                  start={`translate(-4px, 0px) scale(1, 1)`}
                  customConfig={defaultConfig}
                  letter={'Android'}
                  useRandom={false}
                  linkalt={'Link zu Play Store'}
                />
              </a>
              <a
                href={'https://apps.apple.com/de/app/1live/id931875846'}
                target={'_blank'}
                rel={'noreferrer'}
              >
                <Mover
                  delay={waiter + 2300}
                  start={`translate(-4px, 0px) scale(1, 1)`}
                  customConfig={defaultConfig}
                  letter={'/ iOS'}
                  useRandom={false}
                  linkalt={'Link zu App Store'}
                />
              </a>
            </p>
            <p>
              <Mover
                delay={waiter + 2400}
                start={`translate(-4px, 0px) scale(1, 1)`}
                customConfig={defaultConfig}
                letter={'App // Cosmo - '}
                useRandom={false}
              />
              <a
                href={
                  'https://play.google.com/store/apps/details?id=de.WDR.COSMO&hl=de&gl=US'
                }
                target={'_blank'}
                rel={'noreferrer'}
              >
                <Mover
                  delay={waiter + 2400}
                  start={`translate(-4px, 0px) scale(1, 1)`}
                  customConfig={defaultConfig}
                  letter={'Android'}
                  useRandom={false}
                  linkalt={'Link zu Play Store'}
                />
              </a>
              <a
                href={'https://apps.apple.com/de/app/wdr-cosmo/id1509203248'}
                target={'_blank'}
                rel={'noreferrer'}
              >
                <Mover
                  delay={waiter + 2400}
                  start={`translate(-4px, 0px) scale(1, 1)`}
                  customConfig={defaultConfig}
                  letter={'/ iOS'}
                  useRandom={false}
                  linkalt={'Link zu App Store'}
                />
              </a>
            </p>
            <p>
              <Mover
                delay={waiter + 2500}
                start={`translate(-4px, 0px) scale(1, 1)`}
                customConfig={defaultConfig}
                letter={'App // WDR 2 - '}
                useRandom={false}
              />
              <a
                href={
                  'https://play.google.com/store/apps/details?id=de.crowdradio.wdr2&hl=de&gl=US'
                }
                target={'_blank'}
                rel={'noreferrer'}
              >
                <Mover
                  delay={waiter + 2500}
                  start={`translate(-4px, 0px) scale(1, 1)`}
                  customConfig={defaultConfig}
                  letter={'Android'}
                  useRandom={false}
                  linkalt={'Link zu Play Store'}
                />
              </a>
              <a
                href={'https://apps.apple.com/de/app/wdr-2/id859130337'}
                target={'_blank'}
                rel={'noreferrer'}
              >
                <Mover
                  delay={waiter + 2500}
                  start={`translate(-4px, 0px) scale(1, 1)`}
                  customConfig={defaultConfig}
                  letter={'/ iOS'}
                  useRandom={false}
                  linkalt={'Link zu App Store'}
                />
              </a>
            </p>
            <p>
              <Mover
                delay={waiter + 2600}
                start={`translate(-4px, 0px) scale(1, 1)`}
                customConfig={defaultConfig}
                letter={'App // WDR 4 - '}
                useRandom={false}
              />
              <a
                href={
                  'https://play.google.com/store/apps/details?id=de.WDR.WDR4&hl=de&gl=US'
                }
                target={'_blank'}
                rel={'noreferrer'}
              >
                <Mover
                  delay={waiter + 2600}
                  start={`translate(-4px, 0px) scale(1, 1)`}
                  customConfig={defaultConfig}
                  letter={'Android'}
                  useRandom={false}
                  linkalt={'Link zu Play Store'}
                />
              </a>
              <a
                href={'https://apps.apple.com/de/app/wdr-4/id1513178850'}
                target={'_blank'}
                rel={'noreferrer'}
              >
                <Mover
                  delay={waiter + 2600}
                  start={`translate(-4px, 0px) scale(1, 1)`}
                  customConfig={defaultConfig}
                  letter={'/ iOS'}
                  useRandom={false}
                  linkalt={'Link zu App Store'}
                />
              </a>
            </p>
            <p>
              <Mover
                delay={waiter + 2700}
                start={`translate(-4px, 0px) scale(1, 1)`}
                customConfig={defaultConfig}
                letter={'App // WDR 5 - '}
                useRandom={false}
              />
              <a
                href={
                  'https://play.google.com/store/apps/details?id=de.WDR.WDR5&hl=de&gl=US'
                }
                target={'_blank'}
                rel={'noreferrer'}
              >
                <Mover
                  delay={waiter + 2700}
                  start={`translate(-4px, 0px) scale(1, 1)`}
                  customConfig={defaultConfig}
                  letter={'Android'}
                  useRandom={false}
                  linkalt={'Link zu Play Store'}
                />
              </a>
              <a
                href={'https://apps.apple.com/us/app/wdr-5/id1460138818'}
                target={'_blank'}
                rel={'noreferrer'}
              >
                <Mover
                  delay={waiter + 2700}
                  start={`translate(-4px, 0px) scale(1, 1)`}
                  customConfig={defaultConfig}
                  letter={'/ iOS'}
                  useRandom={false}
                  linkalt={'Link zu App Store'}
                />
              </a>
            </p>
            <p>
              <Mover
                delay={waiter + 2800}
                start={`translate(-4px, 0px) scale(1, 1)`}
                customConfig={defaultConfig}
                letter={'App // AWISTA - '}
                useRandom={false}
              />
              <a
                href={
                  'https://play.google.com/store/apps/details?id=ch.glue.android.awista&hl=de&gl=US'
                }
                target={'_blank'}
                rel={'noreferrer'}
              >
                <Mover
                  delay={waiter + 2800}
                  start={`translate(-4px, 0px) scale(1, 1)`}
                  customConfig={defaultConfig}
                  letter={'Android'}
                  useRandom={false}
                  linkalt={'Link zu Play Store'}
                />
              </a>
              <a
                href={'https://apps.apple.com/de/app/awista/id588304622'}
                target={'_blank'}
                rel={'noreferrer'}
              >
                <Mover
                  delay={waiter + 2800}
                  start={`translate(-4px, 0px) scale(1, 1)`}
                  customConfig={defaultConfig}
                  letter={'/ iOS'}
                  useRandom={false}
                  linkalt={'Link zu App Store'}
                />
              </a>
            </p>
            <p>
              <Mover
                delay={waiter + 2900}
                start={`translate(-4px, 0px) scale(1, 1)`}
                customConfig={defaultConfig}
                letter={'Web // Opel - '}
                useRandom={false}
              />
              <a
                href={'https://opel.mobi/DE_de/resp-vis/0J_68/2021A/all/'}
                target={'_blank'}
                rel={'noreferrer'}
              >
                <Mover
                  delay={waiter + 2900}
                  start={`translate(-4px, 0px) scale(1, 1)`}
                  customConfig={defaultConfig}
                  letter={'CoRe Visualizer'}
                  useRandom={false}
                  linkalt={'Link zum CoRe Visualizer'}
                />
              </a>
            </p>
            <p>
              <Mover
                delay={waiter + 2900}
                start={`translate(-4px, 0px) scale(1, 1)`}
                customConfig={defaultConfig}
                letter={'Web // Opel - '}
                useRandom={false}
              />
              <a
                href={
                  'https://www.opel.de/fahrzeuge/insignia-modelle/grand-sport/konfigurator.html'
                }
                target={'_blank'}
                rel={'noreferrer'}
              >
                <Mover
                  delay={waiter + 3000}
                  start={`translate(-4px, 0px) scale(1, 1)`}
                  customConfig={defaultConfig}
                  letter={'Responsive Car Configurator'}
                  useRandom={false}
                  linkalt={'Link zum Responsive Car Configurator'}
                />
              </a>
            </p>
            <p>
              <Mover
                delay={waiter + 3100}
                start={`translate(-4px, 0px) scale(1, 1)`}
                customConfig={defaultConfig}
                letter={'weitere auf Anfrage'}
                useRandom={false}
              />
            </p>
            <p className={'construction top5'}>
              <Mover
                delay={waiter + 3200}
                start={`translate(-4px, 0px) scale(1, 1)`}
                customConfig={defaultConfig}
                letter={'Awards'}
                useRandom={false}
              />
            </p>
            <a
              href={
                'https://www.german-design-award.com/die-gewinner/galerie/detail/16460-opel-core-visualizer.html'
              }
              target={'_blank'}
              rel={'noreferrer'}
            >
              <Mover
                delay={waiter + 3300}
                start={`translate(-4px, 0px) scale(1, 1)`}
                customConfig={defaultConfig}
                letter={'German Design Award 2018'}
                useRandom={false}
                linkalt={'Link zu German Design Award'}
              />
            </a>
            <a
              href={
                'https://www.automotive-brand-contest.de/die-gewinner/galerie/detail/14248-context-responsive-visualizer-core-visualizer.html'
              }
              target={'_blank'}
              rel={'noreferrer'}
            >
              <Mover
                delay={waiter + 3400}
                start={`translate(-4px, 0px) scale(1, 1)`}
                customConfig={defaultConfig}
                letter={'Automotive Brand Contest 2017'}
                useRandom={false}
                linkalt={'Link zu Automotive Brand Contest'}
              />
            </a>
          </div>
          <div className='address top5'>
            <p className={'construction'}>
              <Mover
                delay={waiter + 3500}
                start={`translate(-4px, 0px) scale(1, 1)`}
                customConfig={defaultConfig}
                letter={'Kontakt'}
                useRandom={false}
              />
            </p>
            <p>
              <Mover
                delay={waiter + 3600}
                start={`translate(-6px, 0px) scale(1, 1)`}
                customConfig={defaultConfig}
                letter={'Anemonenweg 7'}
                useRandom={false}
              />
            </p>
            <p>
              <Mover
                delay={waiter + 3700}
                start={`translate(-8px, 0px) scale(1, 1)`}
                customConfig={defaultConfig}
                letter={'97084 Würzburg'}
                useRandom={false}
              />
            </p>

            <p>
              <Mover
                delay={waiter + 3800}
                start={`translate(-8px, 0px) scale(1, 1)`}
                customConfig={defaultConfig}
                letter={'(+49) 015758319772'}
                useRandom={false}
              />
            </p>

            <a href={'mailto:info@paulmura.com'}>
              <Mover
                delay={waiter + 3900}
                start={`translate(-8px, 0px) scale(1, 1)`}
                customConfig={defaultConfig}
                letter={'info@paulmura.com'}
                useRandom={false}
              />
            </a>
          </div>
        </div>
        <CookieConsent
          openDatenschutz={() => {
            moreInfoSetter('/datenschutz')
          }}
          cookie={cookie}
          setcookie={setcookie}
        />
        <MoreInfo open={activeImpressum} setOpen={moreInfoSetter} />
      </div>
    </Router>
  )
}
let prevOpen = ''

const MoreInfo = ({ open, setOpen }) => {
  const windowHeight = useWindowSize()
  let { pathname } = useLocation()
  const impRef = useRef(null)
  const datenRef = useRef(null)
  const clientheight95 = windowHeight * 0.95
  const clientheight5 = windowHeight * 0.1
  const props = useSpring({
    top: pathname !== '/' ? 0 : clientheight95,
  })
  const headline = useSpring({
    paddingTop: pathname !== '/' ? clientheight5 : 0,
    height: windowHeight-clientheight5
  })

  prevOpen = pathname !== '/' ? pathname : prevOpen
  const isDatenschutz =
    pathname === '/datenschutz' || prevOpen === '/datenschutz'
  const isImpressum = pathname === '/impressum' || prevOpen === '/impressum'

  useEffect(() => {
    datenRef.current && datenRef.current.scrollTo(0, 0)
    impRef.current && impRef.current.scrollTo(0, 0)
  }, [open])

  useEffect(() => {
    datenRef.current && datenRef.current.scrollTo(0, 0)
    impRef.current && impRef.current.scrollTo(0, 0)
  }, [pathname])

  return (
    <animated.div className={`view impressum`} style={props}>
      <animated.div style={headline}>
        <h1>
          <Link
            to={pathname === '/impressum' ? '/' : '/impressum'}
            onClick={() => {
              // setpathname((p) => (p === '/impressum' ? null : '/impressum'))
              
            }}
          >
            <span className={pathname === '/impressum' ? 'activeitem' : ''}>
              Impressum
            </span>
          </Link>
          <span className={'divider'}>|</span>
          <Link
            to={pathname === '/datenschutz' ? '/' : '/datenschutz'}
            onClick={() => {
              // setOpen((p) => (p === '/datenschutz' ? null : '/datenschutz'))
              console.log('asdfasdf')
              
            }}
          >
            <span className={pathname === '/datenschutz' ? 'activeitem' : ''}>
              Datenschutz
            </span>
          </Link>
          <span className={'divider'}>|</span>
          <span className={'socials'}>
            <a
              href={'https://www.linkedin.com/in/paul-mura-b4a201208/'}
              target={'_blank'}
              rel={'noreferrer'}
            >
              <img src={linkedin} alt={'Link zum LinkedIn Profil'} />
            </a>
            <a
              href={'https://www.xing.com/profile/Paul_Mura/cv'}
              target={'_blank'}
              rel={'noreferrer'}
            >
              <img src={xing} alt={'Link zum Xing Profil'} className={'xing'} />
            </a>
          </span>
        </h1>

        {/* Datenschutz */}
        <div
          ref={datenRef}
          style={{
            display: isDatenschutz ? 'block' : 'none',
            paddingBottom: isDatenschutz ? '50px' : 0,
          }}
        >
          <Datenschutz />
        </div>

        {/* Impressum */}
        <div
          ref={impRef}
          style={{
            display: isImpressum ? 'block' : 'none',
            paddingBottom: isImpressum ? '50px' : 0,
          }}
        >
          <Impressum />
        </div>
      </animated.div>
    </animated.div>
  )
}

function useWindowSize() {
  const [windowSize, setWindowSize] = useState(window.innerHeight)

  useEffect(() => {
    function handleResize() {
      setWindowSize(window.innerHeight)
    }

    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}
export default App
