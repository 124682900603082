import React, { useCallback, useEffect, useState } from 'react'
import { animated, useSpring } from 'react-spring'
import link from '../assets/images/external-link.svg'
import '../App.css'

const Mover = ({ letter, cssClass = '', trigger = true, delay = 0, start=`translate(-5px, 0px) scale(1, 1)`, useRandom = true, customConfig, linkalt }) => {
  const [visible, setvisible] = useState(false)
  const configSelf = customConfig || { mass: 4, tension: 500, friction: 300 }
  const props = useSpring({
    config: configSelf,
    transform: !visible ? start : 'translate(0px, 0px) scale(1, 1)'
  })

  const props2 = useSpring({
    config: configSelf,
    opacity: !visible ? 0 : 1
  })
  const ms = 2000

  const randomizer = useCallback(() => {
    setTimeout(() => {
      setvisible(state => !state)
    }, ms * Math.random() + delay)
  }, [delay])
  
  const delayer = useCallback(() => {
    setTimeout(() => {
      setvisible(state => !state)
    }, delay)
  }, [delay])

  useEffect(() => {
    // randomizer()
    useRandom ? randomizer() : delayer()
  }, [trigger, randomizer])

  return (
    <animated.span style={{...props, ...props2}} className={`${cssClass} mover`}>
      {letter || React.Children.map(child => child)}
      {linkalt && (<img className={'linkicon'} src={link} alt={linkalt}/>)}
    </animated.span>
  )
}

export default Mover