import React from 'react';
import {
  Link,
  useLocation
} from "react-router-dom";
import '../App.css';

const CookieConsent = ({ openDatenschutz, cookie, setcookie }) => {
  let { pathname } = useLocation();
  if (!cookie) {
    return null
  }

  const handleDatenschutz = () => {
    openDatenschutz()
  }

  return (
    <div className={'cookie'} style={{
      bottom: pathname === '/' ? '6%' : 0
    }}>
      <div>
        <div className={'cookieInfo'}>
          <p>
            Diese Website benutzt Cookies. Wenn du die Website weiter nutzt,
            gehen wir von deinem Einverständnis aus. Weitere Informationen
            findest du im <Link to={'/datenschutz'}><span onClick={handleDatenschutz}>Datenschutz</span></Link>
          </p>
        </div>
        <div className={'cookieBtn'}>
          <div onClick={() => setcookie(false)}>
            <p>OK</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CookieConsent
